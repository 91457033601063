.imprint-root {
  margin-top: 50px;
  padding-left: 10%;
  color: white;
  text-align: left;
}

.section-title {
  font-size: 30px;
  margin-top: 30px;
}