.dp-root {
    width: 70%;
    margin-top: 50px;
    padding-left: 10%;
    color: white;
    text-align: left;
}

.section-title {
    font-size: 30px;
    margin-top: 30px;
    text-decoration: underline;
}

.subsection-title {
    font-size: 23px;
    margin-top: 30px;
    font-weight: 600;
}


.subsubsection-title {
    font-size: 21px;
    margin-top: 30px;
    font-style: italic;
}