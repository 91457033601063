.HomeRoot {
    margin-top: 100px;
    text-align: center;
    align-items: center;
}


.App-Desc {
    color: #40e0d0;
    font-weight: bold;
    font-size: 30px;
    margin-top: 70px;
    text-align: center;
  }