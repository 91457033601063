
html {  
  background-color: #282c34;
}

body {
  font-family: 'Roboto Slab';
}

.App-Desc {
  color: #40e0d0;
  font-weight: bold;
  font-size: 30px;
  margin-top: 70px;
}

.App {
  text-align: center;
}

.App-logo {
  width: 50%;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}